import { render, staticRenderFns } from "./download.vue?vue&type=template&id=aebe6a68&scoped=true&"
import script from "./download.vue?vue&type=script&lang=js&"
export * from "./download.vue?vue&type=script&lang=js&"
import style0 from "./download.vue?vue&type=style&index=0&id=aebe6a68&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/.store/vue-loader@15.10.2/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "aebe6a68",
  null
  
)

export default component.exports